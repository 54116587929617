import { isDefined } from "@clipboard-health/util-ts";
import { MappingLevel } from "@src/appV2/Accounts/Documents/types";
import { APP_V2_APP_EVENTS, useToast } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import { BookabilityUnmetCriteria } from "@src/appV2/OpenShifts/ShiftAction/types";
import {
  type AcceptOrDeclineShiftInviteErrorResponse,
  type AcceptShiftInviteProps,
  type MissingDocument,
  shiftInvitesErrorSchema,
  ShiftInviteStatus,
} from "@src/appV2/Shifts/ShiftInvites/types";
import { type AxiosError } from "axios";
import pluralize from "pluralize";

import { ShiftInviteErrors } from "../constant";
import { useShiftInviteItemContext } from "../context/shiftInviteItemContext";
import { getConflictingShift, getConflictingShiftInvites } from "../utils";
import { logShiftInviteConflictEvent } from "./shiftInviteActionHelper";
import { useAcceptOrDeclineShiftInvite } from "./useAcceptOrDeclineShiftInvite";
import { useAgentPendingShiftInvites } from "./useAgentPendingShiftInvites";
import { useAgentUpcomingShifts } from "./useAgentUpcomingShifts";

export function useShiftInviteActions() {
  const {
    mutateAsync: mutateAcceptOrDecline,
    isLoading: isLoadingAcceptOrDecline,
    variables: acceptOrDeclineRequest,
  } = useAcceptOrDeclineShiftInvite();
  const { refetch: refreshPendingInvites, data: pendingShiftInvites } =
    useAgentPendingShiftInvites();
  const { data: upcomingShifts } = useAgentUpcomingShifts({ upcoming: true });
  const { showSuccessToast, showErrorToast } = useToast();
  const {
    setConflictingShift,
    conflictingInvites,
    setConflictingInvites,
    setHasAgreedToDeclineConflictingInvites,
    conflictingShiftDialogState,
    conflictingInviteDialogState,
    overworkingShiftDialogState,
    missingFacilityRequiredDocumentsDialogState,
    missingCoreRequiredDocumentsDialogState,
    setMissingDocuments,
    weeklyHoursLimitExceededDialogState,
    setWeeklyHoursLimit,
  } = useShiftInviteItemContext();

  interface HandleShiftInviteErrorProps {
    error: AxiosError;
    status: ShiftInviteStatus;
  }

  function handleMissingDocuments(missingDocuments: MissingDocument[]): boolean {
    if (missingDocuments?.every((missingDocument) => missingDocument.level === MappingLevel.HCF)) {
      setMissingDocuments(missingDocuments.map((missingDocument) => missingDocument.name));
      missingFacilityRequiredDocumentsDialogState.openModal();
      return true;
    }

    if (missingDocuments) {
      setMissingDocuments(
        missingDocuments
          .filter((missingDocument) => missingDocument.level !== MappingLevel.HCF)
          .map((coreDocument) => coreDocument.name)
      );
      missingCoreRequiredDocumentsDialogState.openModal();
      return true;
    }

    return false;
  }

  function handleUnmetCriteria(
    unmetCriteria: BookabilityUnmetCriteria | undefined,
    status: ShiftInviteStatus
  ) {
    if (unmetCriteria === BookabilityUnmetCriteria.SHIFT_OVERWORKING) {
      overworkingShiftDialogState.openModal();
      return true;
    }

    if (isDefined(unmetCriteria) && unmetCriteria in ShiftInviteErrors) {
      showErrorToast(ShiftInviteErrors[unmetCriteria]);
    } else {
      showErrorToast(
        `Failed to ${status.toLocaleLowerCase()} shift invite. Please try again later.`
      );
    }

    return false;
  }

  function handleWeeklyHoursLimitExceeded(weeklyHoursLimit: number) {
    setWeeklyHoursLimit(weeklyHoursLimit);
    weeklyHoursLimitExceededDialogState.openModal();
  }

  function handleShiftInviteError(handleShiftInviteErrorProps: HandleShiftInviteErrorProps) {
    const { error, status } = handleShiftInviteErrorProps;
    if (isDefined(error?.response)) {
      const parsedErrorResult = shiftInvitesErrorSchema.safeParse(error.response.data);

      if (parsedErrorResult.success) {
        const data = error?.response?.data as AcceptOrDeclineShiftInviteErrorResponse;
        const unmetCriteria = data?.errors?.[0]?.reasons?.[0];
        const missingDocuments = data?.errors?.[0]?.missingDocuments;
        const weeklyHoursRestriction = data?.errors?.[0]?.weeklyHoursRestriction;

        if (isDefined(weeklyHoursRestriction) && weeklyHoursRestriction.exceeded) {
          handleWeeklyHoursLimitExceeded(weeklyHoursRestriction.limit);
          return;
        }

        if (isDefined(missingDocuments) && handleMissingDocuments(missingDocuments)) {
          return;
        }

        if (handleUnmetCriteria(unmetCriteria, status)) {
          return;
        }
      } else {
        logError(APP_V2_APP_EVENTS.SHIFT_INVITE_SCHEMA_VALIDATION_ERROR, { error });
      }
    }

    // reset this state except in the early return case of overworking
    setHasAgreedToDeclineConflictingInvites(false);
  }

  async function declineConflictingInvites() {
    if (conflictingInvites.length > 0) {
      const pluralizedInvite = pluralize("invite", conflictingInvites.length);
      try {
        await Promise.all(
          conflictingInvites.map(
            async (invite) =>
              await mutateAcceptOrDecline({
                shiftInviteId: invite.id,
                status: ShiftInviteStatus.DECLINED,
              })
          )
        );
        showSuccessToast(
          `The conflicting shift ${pluralizedInvite} ${pluralize(
            "was",
            conflictingInvites.length
          )} ${ShiftInviteStatus.DECLINED.toLocaleLowerCase()} successfully.`
        );
      } catch (error) {
        const axiosError = error as AxiosError;
        if (isDefined(axiosError?.response)) {
          const parsedErrorResult = shiftInvitesErrorSchema.safeParse(axiosError.response.data);

          if (parsedErrorResult.success) {
            showErrorToast(
              `The conflicting shift ${pluralizedInvite} could not be ${ShiftInviteStatus.DECLINED.toLocaleLowerCase()}, please try again manually later.`
            );
          } else {
            logError(APP_V2_APP_EVENTS.SHIFT_INVITE_SCHEMA_VALIDATION_ERROR, { error });
          }
        }
      }
    }

    setHasAgreedToDeclineConflictingInvites(false);
  }

  async function acceptShiftInvite(acceptShiftInviteProps: AcceptShiftInviteProps) {
    const {
      shiftInvite,
      isOverworkingShiftsAllowed = false,
      shouldDeclineConflictingInvites = false,
      skipFacilityRequiredDocumentCheck = false,
    } = acceptShiftInviteProps;
    const {
      id: shiftInviteId,
      attributes: { shiftDetails: shiftInviteDetails, workplaceId },
    } = shiftInvite;
    const result = getConflictingShift(upcomingShifts ?? [], {
      ...shiftInviteDetails,
      facilityId: workplaceId,
    });
    if (isDefined(result)) {
      setConflictingShift(result);
      logShiftInviteConflictEvent(shiftInviteId, result, shiftInviteDetails);
      conflictingShiftDialogState.openModal();
      return;
    }

    const conflictingInviteResults = getConflictingShiftInvites(
      (pendingShiftInvites?.data ?? []).filter((invite) => invite.id !== shiftInviteId),
      shiftInvite
    );
    if (conflictingInviteResults.length > 0 && !shouldDeclineConflictingInvites) {
      setConflictingInvites(conflictingInviteResults);
      conflictingInviteDialogState.openModal();
      return;
    }

    try {
      await mutateAcceptOrDecline({
        shiftInviteId,
        status: ShiftInviteStatus.ACCEPTED,
        isOverworkingShiftsAllowed,
        skipFacilityRequiredDocumentCheck,
      });
      showSuccessToast(
        `Shift invite ${ShiftInviteStatus.ACCEPTED.toLocaleLowerCase()} successfully.`
      );
      if (shouldDeclineConflictingInvites) {
        await declineConflictingInvites();
      }
    } catch (error) {
      handleShiftInviteError({ error: error as AxiosError, status: ShiftInviteStatus.ACCEPTED });
    }

    await refreshPendingInvites();
  }

  interface DeclineShiftInviteProps {
    shiftInviteId: string;
  }
  async function declineShiftInvite(declineShiftInviteProps: DeclineShiftInviteProps) {
    const { shiftInviteId } = declineShiftInviteProps;
    try {
      await mutateAcceptOrDecline({ shiftInviteId, status: ShiftInviteStatus.DECLINED });
      showSuccessToast(
        `Shift invite ${ShiftInviteStatus.DECLINED.toLocaleLowerCase()} successfully.`
      );
    } catch (error) {
      const axiosError = error as AxiosError;
      if (isDefined(axiosError?.response)) {
        const parsedErrorResult = shiftInvitesErrorSchema.safeParse(axiosError.response.data);

        if (parsedErrorResult.success) {
          showErrorToast(
            `This invite could not be ${ShiftInviteStatus.DECLINED.toLocaleLowerCase()}, please try again later.`
          );
        } else {
          logError(APP_V2_APP_EVENTS.SHIFT_INVITE_SCHEMA_VALIDATION_ERROR, { error });
        }
      }
    }

    await refreshPendingInvites();
  }

  return {
    acceptShiftInvite,
    declineShiftInvite,
    isLoadingAcceptOrDecline,
    acceptOrDeclineRequest,
  };
}
